.headerContainer.headerContainerOpened {
    position: relative;
    height: 78rem;
}

.headerContainer {
    position: relative;
    height: 15rem;
}

.headerContainer > #headerFrame {
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
}